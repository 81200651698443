import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import logo from "../../assets/logo.png";
// import logoBruseco from "../../assets/logoBruseco.png";
import * as dayjs from "dayjs";
import { validateArray } from "../../utils/validate";

const GenerateRoadmap = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/Bruseco/HojaDeRuta/${procedure.id || procedure.procedureId}`,
  });

  function handleListObservation() {
    const listObs = [
      {
        label: "Observaciones (Recepcion y revision)",
        name: "observaciones",
      },
      {
        label: "Observaciones de los documentos",
        name: "observacionesDeLosDocumentos",
      },
      {
        label: "Observaciones de la DAM",
        name: "observacionesDeLaDam",
      },
      {
        label: "Respuesta a las observaciones de la DAM (paso 6)",
        name: "respuestaALasObservaciones",
      },
      {
        label: "Observaciones de parte de recepción (paso 8)",
        name: "observacionesDeParteDeRecepcion",
      },
      {
        label: "Descripcion de la evaluacion (Paso 10)",
        name: "descripcionDeLaEvaluacion",
      },
      {
        label: "Observaciones de proforma (Paso 11)",
        name: "observacionesDeProforma",
      },
      {
        label: "Observaciones de la DIM (Paso 15)",
        name: "observacionesDeLaDim",
      },
      {
        label: "Respuesta a las observaciones (DIM) (Paso 16)",
        name: "respuestaALasObservacionesDim",
      },
      {
        label: "Informe de evaluación (Paso 20)",
        name: "informeDeEvaluacion",
      },
      {
        label: "Detalles de la entrega (Paso 27)",
        name: "detallesDeLaEntrega",
      },
      {
        label: "Observaciones de la verificación (Paso 28)",
        name: "observacionesDeLaVerificacion",
      },
    ];
    let listResult = [];

    listObs.forEach((element) => {
      if (data[element.name] && data[element.name] !== "-") {
        listResult.push({
          observation: element.label,
          description: data[element.name],
        });
      }
    });

    return listResult;
  }

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent style={{ marginBottom: "5px" }}>
          <View
            width="25%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} style={{ height: "40px" }} />
          </View>
          <View
            flexDirection="column"
            width="50%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="0 10px"
          >
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              MAEX S.R.L.
            </Text>
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              AGENCIA DESPACHANTE DE ADUANA
            </Text>
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              HOJA DE RUTA Y REGISTRO DE DOCUMENTOS
            </Text>
          </View>
          <View width="25%" justifyContent="center" alignItems="center">
            <Text
              textAlign="center"
              color="#1f497d"
              fontSize="14px"
              padding="2px 3px"
              bold
            >
              Nº {data.numeroDeInterno}
            </Text>
          </View>
        </View>
        <View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              FECHA Y HORA
            </Text>
            <Text border={{ top: true }}>
              {dayjs(data.date).format("DD/MM/YYYY HH:mm")}
            </Text>
          </View>
          <View
            width="50%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              NOMBRE IMPORTADOR / EXPORTADOR
            </Text>
            <Text border={{ top: true }}>{data.razonSocial}</Text>
          </View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              Nº INTERNO
            </Text>
            <Text border={{ top: true }}>{data.numeroDeInterno}</Text>
          </View>
        </View>

        <View style={{ margin: "2px 0" }}>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              Nº DE DIM
            </Text>
            <Text border={{ top: true }}>{data.numeroDim}</Text>
          </View>
          <View
            width="25%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              ADUANA
            </Text>
            <Text border={{ top: true }}>{data.aduana}</Text>
          </View>
          <View
            width="25%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              CANAL
            </Text>
            <Text border={{ top: true }}>{data.canal}</Text>
          </View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              FECHA DE PAGO
            </Text>
            <Text border={{ top: true }}>
              {data.fechaDePagoDeLaDim && data.fechaDePagoDeLaDim != null && data.fechaDePagoDeLaDim !== '-' ? dayjs(data.fechaDePagoDeLaDim).format("DD/MM/YYYY HH:mm") : ''}
              {/* {data.fechaDePago && data.fechaDePago !== "null"
                ? dayjs(data.fechaDePago).format("DD/MM/YYYY HH:mm")
                : ""} */}
            </Text>
          </View>
        </View>

        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          REQUERIMIENTOS DE CLIENTE
        </Text>
        <View>
          <View
            width="50%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px 0 0" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              IMPORTACIÓN
            </Text>
            <Text border={{ top: true }}>{data.tipoDeImportacion}</Text>
          </View>
          <View width="50%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              EXPORTACIÓN
            </Text>
            <Text border={{ top: true }}>{data.tipoDeExportacion}</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          DOCUMENTO PARA EL DESPACHO
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "Nº",
              name: "index",
              type: "index",
              width: "5",
            },
            {
              label: "DOCUMENTO",
              name: "name",
              width: "25",
            },
            {
              label: "Nº DOCUMENTO",
              name: "number",
              width: "25",
            },
            {
              label: "PROVEEDOR",
              name: "provider",
              width: "20",
            },
            {
              label: "ENLACE",
              name: "url",
              type: "link",
              width: "25",
            },
          ]}
          border={{ top: true, right: true, bottom: true, left: true }}
          data={data.historialReceptionVerificationDocuments}
        />

        <View style={{ marginTop: "5px" }}>
          <View width="50%" flexDirection="column">
            <View border={{ top: true, right: true, bottom: true, left: true }}>
              <Text bold style={{ margin: "1px auto" }}>
                DOCUMENTO DE FACTURACIÓN A ARCHIVO
              </Text>
            </View>
            <CustomTablePDF
              fields={[
                {
                  label: "TIPO DE DOCUMENTO",
                  name: "doc",
                  width: "50",
                },
                {
                  label: "Nº DOCUMENTO",
                  name: "nro",
                  width: "50",
                },
              ]}
              border={{ right: true, bottom: true, left: true }}
              data={[
                { doc: "Factura", nro: data.numeroFactura },
                { doc: "Planilla", nro: data.numeroDeInterno },
                { doc: "Nro Contable", nro: data.numeroNotaContable },
                { doc: " ", nro: " " },
                { doc: " ", nro: " " },
              ]}
            />
          </View>
          <View width="50%" flexDirection="column">
            <View border={{ top: true, right: true, bottom: true, left: true }}>
              <Text bold style={{ margin: "1px auto" }}>
                TRIBUTOS A PAGAR
              </Text>
            </View>
            <CustomTablePDF
              fields={[
                {
                  label: "TIPO",
                  name: "doc",
                  width: "50",
                },
                {
                  label: "MONTO",
                  name: "amount",
                  width: "50",
                },
              ]}
              border={{ right: true, bottom: true, left: true }}
              data={[
                { doc: "GA", amount: data.ga },
                { doc: "IVA", amount: data.iva },
                { doc: "ICE", amount: data.ice },
                { doc: "ICED", amount: data.iceD },
                { doc: "TOTAL", amount: data.total },
              ]}
            />
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          RECEPCIÓN DE DOCUMENTO
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "RESP. DE ENTREGA Y RECEPCIÓN",
              name: "rol",
              width: "25",
            },
            {
              label: "NOMBRE",
              name: "name",
              width: "42",
            },
            {
              label: "FIRMA",
              name: "firm",
              width: "33",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={[
            { rol: "LIQUIDADOR ASIGNADO", name: data.liquidador, firm: " " },
            { rol: "GESTOR ASIGNADO", name: data.gestor, firm: " " },
          ]}
        />

        <CustomTablePDF
          fields={[
            {
              label: "RESP. DE ENTREGA Y RECEPCION",
              name: "resp",
              width: "25",
            },
            {
              label: "FECHA / HORA",
              name: "date",
              type: "date",
              width: "42",
            },
            {
              label: "FIRMA",
              name: "firm",
              width: "33",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={[
            {
              resp: "IMPRESIÓN DE HOJA DE RUTA",
              firm: " ",
            },
          ]}
        />

        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          COMENTARIOS Y OBSERVACIONES
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "Observación",
              name: "observation",
              width: "30",
            },
            {
              label: "Descripción",
              name: "description",
              width: "70",
            },
          ]}
          border={{ right: true, left: true }}
          data={handleListObservation()}
        />
        {validateArray(data.subDataObservacionesDeLaDim) ? (
          <View>
            <View
              width="30%"
              border={{ top: true, right: true, bottom: true, left: true }}
            >
              <Text>Observaciones de la DIM</Text>
            </View>
            <View width="70%" border={{ top: true, right: true, bottom: true }}>
              {data.subDataObservacionesDeLaDim.map((item) => (
                <Text>
                  {item.label}: {item.description}
                </Text>
              ))}
            </View>
          </View>
        ) : null}

        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          TRAZABILIDAD POR PROCESOS
        </Text>
        <Text bold border={{ right: true, bottom: true, left: true }}>
          Duración Total: {data.totalDuration}
        </Text>
        <CustomTablePDF
          fields={[
            // {
            //   label: 'Nro',
            //   name: 'label',
            //   width: '5',
            // },
            {
              label: "Encargado",
              name: "userInChange",
              width: "24",
            },
            {
              label: "Proceso",
              name: "stepName",
              width: "19",
            },
            {
              label: "Inicio",
              name: "initialDate",
              type: "date",
              width: "13",
            },
            {
              label: "Final",
              name: "finalDate",
              type: "date",
              width: "13",
            },
            {
              label: "Duración",
              name: "durationString",
              width: "31",
            },
          ]}
          data={data.procedureHistories}
          border={{ right: true, bottom: true, left: true }}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateRoadmap;
