import * as Yup from "yup";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Request } from "../../hooks/Request";
import { validateArray } from "../../utils/validate";
import { Button } from "../../components/Buttons";
import { useModal } from "../../contexts/ModalContext";
import { requestAuth } from "../services/RequestService";
import FormDefault from "../../components/Forms/FormDefault";

const GeneralDataDam = ({ urlApi, reload }) => {
  const { procedureId } = useParams();

  const { data, loading, call } = Request({
    urlApi: urlApi ? urlApi : null,
  });

  function changeData(head) {
    let listResume = { head: head.head };
    if (validateArray(head.items)) {
      head.items.forEach((item) => {
        listResume = { ...listResume, [item.name]: item };
      });
    }
    return listResume;
  }

  if (!loading) {
    return null;
  }

  return (
    <div>
      <RenderGeneralData
        call={call}
        procedureId={procedureId}
        headA={changeData(data[0])}
        headB={changeData(data[1])}
        headC={changeData(data[2])}
        headD={changeData(data[3])}
        Total={changeData(data[4])}
      />
    </div>
  );
};

const RenderGeneralData = (props) => {
  const { headA, headB, headC, headD, Total, procedureId, call } = props;
  const { openModal, onClose } = useModal();

  function handleFields(json) {
    let resultInitial = {};
    let resultFields = [];
    let resultValidation = {};
    for (const fieldName in json) {
      const field = json[fieldName];
      console.log(fieldName, field.validate);
      resultInitial = {
        ...resultInitial,
        [field.name]:
          field.type !== "selectGetMultiple"
            ? field.value || ""
            : field.value ? field.value.split(";").map((item) => ({
                value: item,
                label: item,
              })): "",
      };
      // "a3N°DeReferencia(Dam)": Yup.string().required("Campo requerido"),

      resultValidation = {
        ...resultValidation,
        [fieldName]: field.validate
          ? Yup.string().required("Campo requerido")
          : null,
      };
      // console.log(field.dropdownList);
      resultFields.push({
        ...field,
        label: field.label,
        name: field.name,
        placeholder: "Introduzca los datos",
        type: field.type,
        typeInput: field.type,
        urlApi:
          field.type === "subSelect" && field.dropdownList
            ? field.dropdownList.urlLabel
            : field.urlLabel
            ? field.urlLabel.includes("/")
              ? field.urlLabel
              : `/Dropdown/${field.urlLabel}/options`
            : "",
        fatherName:
          field.type === "subSelect" && field.dropdownList
            ? field.dropdownList.name
            : "",
        fatherUrl:
          field.type === "subSelect" && field.dropdownList
            ? field.dropdownList.urlLabel.includes("/")
              ? field.dropdownList.urlLabel
              : `/Dropdown/${field.dropdownList.urlLabel}/options`
            : "",
        value: "name",
        labelOption: "name",
        // lockEdition: field.name === "a1N°DeDeclaración(Dam)",
      });
    }
    return {
      initial: resultInitial,
      fields: resultFields,
      validate: resultValidation,
    };
  }

  function handleOpenForm(dataForm) {
    const { head, ...rest } = dataForm;
    const { initial, fields, validate } = handleFields(rest);
    openModal(
      <FormDefault
        tittle={`Formulario de ${dataForm?.head}`}
        fields={fields}
        initialValues={initial}
        validationSchema={Yup.object().shape(validate)}
        onSubmit={async (values, resetForm) =>
          await onSubmit(values, resetForm, fields)
        }
        buttonName="Registrar"
      />
    );
  }

  async function onSubmit(values, resetForm, fields) {
    const result = validateArray(fields)
      ? fields.map((item) => {
          if (values[item.name]) {
            return {
              Value:
                item.type === "selectGetMultiple"
                  ? validateArray(values[item.name])
                    ? values[item.name].map((item) => item.value).join(";")
                    : ""
                  : values[item.name],
              ProcedureId: procedureId,
              FieldId: item.fieldId,
            };
          }
          return null;
        })
      : [];
    await onSubmitDataSet(
      result.filter((n) => n),
      resetForm
    );
  }

  async function onSubmitDataSet(values, resetForm) {
    await requestAuth("post", "/dataset", values)
      .then(() => {
        call();
        resetForm();
        onClose();
        toast.success("Envio exitoso");
      })
      .catch((response) => {
        toast.error(
          response.response.data
            ? response.response.data
            : "Se ha producido un error al enviar"
        );
      });
  }

  const CellData = ({ title, children, className }) => {
    return (
      <div
        className={`w-1/3 px-2 py-0 border-b-2 border-r-2 border-blue-900 ${className}`}
      >
        <h4 className="font-semibold">{title}</h4>
        <h4>{children}</h4>
      </div>
    );
  };

  return (
    <div>
      <div className="flex border-2 border-blue-900 mb-2 rounded-md">
        <div className="bg-blue-900 w-2"></div>
        <div className="w-full">
          <div className="w-full flex justify-start items-center gap-4 py-1 pl-3 text-lg font-semibold border-b-2 border-blue-900 text-blue-900 bg-blue-500">
            <h3 className="font-bold text-white">
              A. Identificación de la Declaración
            </h3>
            <Button
              onClick={() => handleOpenForm(headA)}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <i className="fab fa-wpforms"></i>
            </Button>
          </div>
          <div className="w-full flex">
            <CellData title="A3. N° de Referencia">
              {headA["a3N°DeReferencia(Dam)"].value}
            </CellData>
            <CellData title="A4. Aduana de despacho">
              {headA["a4AduanaDeDespacho(Dam)"].value}
            </CellData>
            <CellData className="border-r-0" title="A5. Forma de envío">
              {headA["a5FormaDeEnvío(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData title="A5.1 Es carga consolidada?">
              {headA["a5_1CargaConsolidada(Dam)"].value}
            </CellData>
            <CellData title="A6. Destino/Régimen aduanero">
              {headA["a6Destino/RégimenAduanero(Dam)"].value}
            </CellData>
            <CellData className="border-r-0" title="A7. Modalidad del régimen">
              {headA["a7ModalidadDeRégimen(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData title="A8. Modalidad de despacho">
              {headA["a8ModalidadDeDespacho(Dam)"].value}
            </CellData>
            <CellData title="A9. Destino/Régimen Posterior a depósito">
              {headA["a9Destino/RégimenPosteriorADepósito(Dam)"].value}
            </CellData>
            <CellData
              className="border-r-0"
              title="A10. Modalidad de Despacho Posterior a Depósito"
            >
              {headA["a10ModalidadDeDespachoPosteriorADepósito(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData
              className="border-b-0"
              title="A11. Emisión de Parte de Recepción"
            >
              {headA["a11EmisiónDeParteDeRecepción(Dam)"].value}
            </CellData>
            <CellData className="border-b-0" title="A12. Tipo de mercancia">
              {headA["a12TipoDeMercancia(Dam)"].value}
            </CellData>
          </div>
        </div>
      </div>
      <div className="flex border-2 border-blue-900 mb-2 rounded-md">
        <div className="bg-blue-900 w-2"></div>
        <div className="w-full">
          <div className="w-full flex justify-start items-center gap-4 py-1 pl-3 text-lg font-semibold border-b-2 border-blue-900 text-blue-900 bg-blue-500">
            <h3 className="font-bold text-white">B. Operadores</h3>
            <Button
              onClick={() => handleOpenForm(headB)}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <i className="fab fa-wpforms"></i>
            </Button>
          </div>
          <div className="w-full flex">
            <CellData title=""></CellData>
            <CellData title="Tipo de documento"></CellData>
            <CellData className="border-r-0" title="N° de documento"></CellData>
          </div>
          <div className="w-full flex">
            <CellData title="">B1. Consignatario</CellData>
            <CellData title="">
              {headB["b1TipoDeDocumento(Dam)"].value}
            </CellData>
            <CellData className="border-r-0" title="">
              {headB["b1NúmeroDeDocumento(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData className="border-b-0" title="">
              B2. Consignatario
            </CellData>
            <CellData className="border-b-0" title="">
              {headB["b2TipoDeDocumento(Dam)"].value}
            </CellData>
            <CellData className="border-b-0 border-r-0" title="">
              {headB["b2NúmeroDeDocumento(Dam)"].value}
            </CellData>
          </div>
        </div>
      </div>
      <div className="flex border-2 border-blue-900 mb-2 rounded-md">
        <div className="bg-blue-900 w-2"></div>
        <div className="w-full">
          <div className="w-full flex justify-start items-center gap-4 py-1 pl-3 text-lg font-semibold border-b-2 border-blue-900 text-blue-900 bg-blue-500">
            <h3 className="font-bold text-white">C. Lugares</h3>
            <Button
              onClick={() => handleOpenForm(headC)}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <i className="fab fa-wpforms"></i>
            </Button>
          </div>
          <div className="w-full flex">
            <CellData title="C1. País de exportación">
              {headC["c1PaísDeExportación(Dam)"].value}
            </CellData>
            <CellData title="C2. País de procedencia">
              {headC["c2PaísDeProcedencia(Dam)"].value}
            </CellData>
            <CellData className="border-r-0" title="C3. País de tránsito">
              {headC["c3PaísDeTránsito(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData className="border-b-0" title="C4. Aduana de ingreso">
              {headC["c4AduanaDeIngreso(Dam)"].value}
            </CellData>
            <CellData className="border-b-0" title="C5. Aduana de destino">
              {headC["c5AduanaDeDestino(Dam)"].value}
            </CellData>
            <CellData
              className="border-b-0 border-r-0"
              title="C6. Lugar de entrega"
            >
              {headC["c6LugarDeEntrega(Dam)"].value}
            </CellData>
          </div>
        </div>
      </div>
      <div className="flex border-2 border-blue-900 mb-2 rounded-md">
        <div className="bg-blue-900 w-2"></div>
        <div className="w-full">
          <div className="w-full flex justify-start items-center gap-4 py-1 pl-3 text-lg font-semibold border-b-2 border-blue-900 text-blue-900 bg-blue-500">
            <h3 className="font-bold text-white">D. Transporte</h3>
            <Button
              onClick={() => handleOpenForm(headD)}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <i className="fab fa-wpforms"></i>
            </Button>
          </div>
          <div className="w-full flex">
            <div className="w-full px-2 py-0 border-b-2 border-blue-900">
              <h4 className="font-semibold">D1. N° de documento de embarque</h4>
              <h4>{headD["d1N°DeDocumentoDeEmbarque(Dam)"].value}</h4>
            </div>
          </div>
          <div className="w-full flex">
            <CellData
              className="border-b-0"
              title="D2. Modalidad de transporte hasta frontera"
            >
              {headD["d2ModalidadDeTransporteHastaFrontera(Dam)"].value}
            </CellData>
            <CellData
              className="border-b-0"
              title="D3. Modalidad de transporte desde frontera"
            >
              {headD["d3ModalidadDeTransporteDesdeFrontera(Dam)"].value}
            </CellData>
            <CellData
              className="border-b-0 border-r-0"
              title="D4. Carga peligrosa"
            >
              {headD["d4CargaPeligrosa(Dam)"].value}
            </CellData>
          </div>
        </div>
      </div>
      <div className="flex border-2 border-blue-900 mb-2 rounded-md">
        <div className="bg-blue-900 w-2"></div>
        <div className="w-full">
          <div className="w-full flex justify-start items-center gap-4 py-1 pl-3 text-lg font-semibold border-b-2 border-blue-900 text-blue-900 bg-blue-500">
            <h3 className="font-bold text-white">
              Totales para control de la declaración
            </h3>
            <Button
              onClick={() => handleOpenForm(Total)}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <i className="fab fa-wpforms"></i>
            </Button>
          </div>
          <div className="w-full flex">
            <CellData title="Total N° facturas">
              {Total["TotalN°Facturas(Dam)"].value}
            </CellData>
            <CellData title="Total N° de items">
              {Total["TotalN°DeItems(Dam)"].value}
            </CellData>
            <CellData className="border-r-0" title="Valor FOB Total (USD)">
              {Total["ValorFOBTotal(USD)(Dam)"].value}
            </CellData>
          </div>
          <div className="w-full flex">
            <CellData className="border-b-0" title="Total N° de bultos">
              {Total["totalN°DeBultos(Dam)"].value}
            </CellData>
            <CellData className="border-b-0" title="Total peso bruto [kg]">
              {Total["totalPesoBruto(kg)(Dam)"].value}
            </CellData>
            <CellData
              className="border-b-0 border-r-0"
              title="Total peso neto [kg]"
            >
              {Total["totalPesoNeto(kg)(Dam)"].value}
            </CellData>
          </div>
        </div>
      </div>
      <br />
      <div className=""></div>
    </div>
  );
};

export default GeneralDataDam;
