import { useState } from "react";
import { Request } from "../../hooks/Request";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { validateArray } from "../../utils/validate";
import { readDim } from "../../components/services/ReadFile";

const FormReadDocument = () => {
  const [procedures, setProcedures] = useState([]);
  const { data, loading } = Request({
    urlApi: `/procedure/unRead`,
  });

  async function handleRead(listProcedure) {
    handleReadRecursive(listProcedure, listProcedure.length - 1);
  }
  async function handleReadRecursive(listProcedure, index) {
    await readDim(
      [
        {
          id: 40,
          initial:
            "https://res.cloudinary.com/dojxoluzj/image/upload/v1695331216/Trazo/Procedure/448/File/2023921_5436.pdf",
          label: "Adjuntar DIM",
          name: "adjuntarDim",
          type: "fileRead",
          unique: false,
          url: "adjuntarDim",
          validate: false,
        },
      ],
      0,
      {
        id: listProcedure[index],
      },
      [],
      (_, __, result) =>
        handleFinishRead(result, listProcedure[index], listProcedure, index)
    );
  }

  function handleFinishRead(result, procedure, listProcedure, index) {
    setProcedures((a) => [...a, { procedure: procedure, result: result }]);
    if (index >= 0) {
      handleReadRecursive(listProcedure, index - 1);
    }
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div className="">
      <Button className="float-right" onClick={() => handleRead(data)}>
        Leer DIM
      </Button>
      {validateArray(procedures)
        ? procedures.map((item) => (
            <div className="border-2 border-gray-500 p-1 rounded-lg mb-2">
              <div className="">{item.procedure}</div>
              {validateArray(item.result)
                ? item.result.map((result) => (
                    <div className="flex">
                      <div className="">{result.readStatus}</div>
                      <div className="">{result.readData}</div>
                    </div>
                  ))
                : null}
            </div>
          ))
        : null}
    </div>
  );
};

export default FormReadDocument;
