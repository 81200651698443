import * as Yup from "yup";
import { useState } from "react";
import { H2, H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { Navigation } from "../../components/Buttons";
import TableGeneral from "../../components/ExcelPages/TableGeneral";
import GeneralDataDam from "../../components/ExcelPages/GeneralDataDam";
import {
  headerProviders,
  headerBills,
  headerItem,
  headerMinimalDescription,
} from "../../utils/constGenerate";

const RenderDAM = (props) => {
  const {
    procedureId,
    exportExcel,
    importExcel,
    typeId,
    rolId,
    handleDelete,
    exportJson,
  } = props;

  const [reload, setReload] = useState(true);
  function call() {
    setReload(!reload);
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <H2>Llenado de la DAM</H2>
        <div className="flex gap-2 items-center">
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Generar Json"
            onClick={exportJson}
          >
            <i className="fas fa-file-import"></i>
          </Button>
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Generar Excel"
            onClick={exportExcel}
          >
            <i className="fas fa-cloud-download-alt"></i>
          </Button>
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Extraer datos de un excel"
            onClick={() => importExcel(call)}
          >
            <i className="fas fa-clone"></i>
          </Button>
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dav`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DAV"
          >
            DAV
          </Button>
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dim`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DIM"
          >
            DIM
          </Button>
        </div>
      </div>
      <Navigation
        actDefault={1}
        navigation={[
          {
            id: 1,
            label: "Datos Generales",
            component: (
              <GeneralDataDam
                urlApi={`/procedure/${procedureId}/sheet/1/dam`}
                reload={call}
              />
            ),
          },
          {
            id: 2,
            label: "Proveedores",
            component: (
              <TableGeneral
                addBtn="Adicionar proveedor"
                editBtn="Editar proveedor"
                handleReload={reload}
                header={[
                  ...headerProviders,
                  {
                    name: "action",
                    type: "action",
                    label: "",
                    hidden: true,
                    actions: [
                      {
                        label: "Eliminar",
                        icon: "fas fa-trash",
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            "el proveedor",
                            `/procedureSheet/provider/${item.id}`
                          ),
                        color: "text-red-500",
                      },
                    ],
                  },
                ]}
                validationSchema={Yup.object().shape({
                  razonSocial: Yup.string().required("Campo requerido"),
                  streetAvenue: Yup.string().required("Campo requerido"),
                  homeNumber: Yup.string().required("Campo requerido"),
                  country: Yup.string().required("Campo requerido"),
                  city: Yup.string().required("Campo requerido"),
                  phone: Yup.string().required("Campo requerido"),
                  email: Yup.string().required("Campo requerido"),
                })}
                urlApi={`/procedure/${procedureId}/sheet/2/dav`}
                urlPost={`/procedureSheet/provider/procedure/${procedureId}`}
                urlPut="/procedureSheet/provider/"
                reload={call}
                procedureId={procedureId}
              />
            ),
          },
          {
            id: 3,
            label: "Facturas",
            component: (
              <TableGeneral
                addBtn="Adicionar factura"
                editBtn="Editar factura"
                handleReload={reload}
                header={[
                  ...headerBills,
                  {
                    name: "action",
                    type: "action",
                    label: "",
                    hidden: true,
                    actions: [
                      {
                        label: "Eliminar",
                        icon: "fas fa-trash",
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            "la factura seleccionada",
                            `/procedureSheet/invoice/${item.id}`
                          ),
                        color: "text-red-500",
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/3/dav`}
                urlPost={`/procedureSheet/invoice/${procedureId}`}
                urlPut="/procedureSheet/invoice/"
                reload={call}
                procedureId={procedureId}
              />
            ),
          },
          {
            id: 4,
            label: "Items",
            component: (
              <TableGeneral
                addBtn="Adicionar item"
                editBtn="Editar item"
                handleReload={reload}
                header={[
                  ...headerItem,
                  {
                    name: "action",
                    type: "action",
                    label: "",
                    hidden: true,
                    actions: [
                      {
                        label: "Eliminar",
                        icon: "fas fa-trash",
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            "el item",
                            `/procedureSheet/item/${item.id}`
                          ),
                        color: "text-red-500",
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/4/dav`}
                urlPost={`/procedureSheet/item/procedure/${procedureId}`}
                urlPut="/procedureSheet/item/"
                reload={call}
                procedureId={procedureId}
              />
            ),
          },
          {
            id: 5,
            label: "Descripciones Mínimas",
            component: (
              <TableGeneral
                addBtn="Adicionar descripción mínima"
                editBtn="Editar descripción mínima"
                handleReload={reload}
                header={[
                  ...headerMinimalDescription,
                  {
                    name: "action",
                    type: "action",
                    label: "",
                    hidden: true,
                    actions: [
                      {
                        label: "Eliminar",
                        icon: "fas fa-trash",
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            "la descripción mínima",
                            `/procedureSheet/minimalDescription/${item.id}`
                          ),
                        color: "text-red-500",
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/5/dav`}
                urlPost={`/procedureSheet/minimalDescription/procedure/${procedureId}`}
                urlPut="/procedureSheet/minimalDescription/"
                reload={call}
                procedureId={procedureId}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default RenderDAM;
