import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import logo from "../../assets/logo.png";
// import logoBruseco from "../../assets/logoBruseco.png";
import * as dayjs from "dayjs";
import { validateArray } from "../../utils/validate";
import { es } from "dayjs/locale/es";
dayjs.locale("es");

const GenerateRoadmap = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/tradecruz/${procedure.id || procedure.procedureId}/HojaDeRuta`,
    // urlApi: `/procedure/${procedure.id || procedure.procedureId}/roadMap`,
    // urlApi: `/Bruseco/HojaDeRuta/${procedure.id || procedure.procedureId}`,
  });

  function handleListObservation() {
    const listObs = [
      {
        label: "Observaciones (Recepcion y revision)",
        name: "observaciones",
      },
      {
        label: "Observaciones de los documentos",
        name: "observacionesDeLosDocumentos",
      },
      {
        label: "Observaciones de la DAM",
        name: "observacionesDeLaDam",
      },
      {
        label: "Respuesta a las observaciones de la DAM (paso 6)",
        name: "respuestaALasObservaciones",
      },
      {
        label: "Observaciones de parte de recepción (paso 8)",
        name: "observacionesDeParteDeRecepcion",
      },
      {
        label: "Descripcion de la evaluacion (Paso 10)",
        name: "descripcionDeLaEvaluacion",
      },
      {
        label: "Observaciones de proforma (Paso 11)",
        name: "observacionesDeProforma",
      },
      {
        label: "Observaciones de la DIM (Paso 15)",
        name: "observacionesDeLaDim",
      },
      {
        label: "Respuesta a las observaciones (DIM) (Paso 16)",
        name: "respuestaALasObservacionesDim",
      },
      {
        label: "Informe de evaluación (Paso 20)",
        name: "informeDeEvaluacion",
      },
      {
        label: "Detalles de la entrega (Paso 27)",
        name: "detallesDeLaEntrega",
      },
      {
        label: "Observaciones de la verificación (Paso 28)",
        name: "observacionesDeLaVerificacion",
      },
    ];
    let listResult = [];

    listObs.forEach((element) => {
      if (data[element.name] && data[element.name] !== "-") {
        listResult.push({
          observation: element.label,
          description: data[element.name],
        });
      }
    });

    return listResult;
  }

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent style={{ marginBottom: "5px" }}>
          <View
            width="30%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} style={{ height: "40px" }} />
          </View>
          <View
            flexDirection="column"
            width="40%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="0 10px"
          >
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              MAEX
            </Text>
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              AGENCIA DESPACHARTE DE ADUANA
            </Text>
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              PLANILLA DE LIQUIDACIÓN
            </Text>
          </View>
          <View width="30%" justifyContent="center" alignItems="center">
            <Text
              textAlign="center"
              color="#1f497d"
              fontSize="14px"
              padding="2px 3px"
              bold
            >
              Nº {data.intern}
            </Text>
          </View>
        </View>

        <Text
          bold
          borderContent
          textAlign="center"
          style={{
            marginBottom: "5px",
            marginTop: "5px",
            backgroundColor: "#dfdfdf",
          }}
        >
          DATOS GENERALES DE LA IMPORTACIÓN
        </Text>
        <View>
          {/* <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              FECHA Y HORA
            </Text>
            <Text border={{ top: true }}>
              {dayjs(data.date).format("DD/MM/YYYY HH:mm")}
            </Text>
          </View> */}
          <View
            width="70%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 0" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              NOMBRE IMPORTADOR / EXPORTADOR
            </Text>
            <Text border={{ top: true }}>{data.razonSocial}</Text>
          </View>
          <View
            width="30%"
            flexDirection="column"
            border={{ top: true, right: true, bottom: true }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              NIT
            </Text>
            <Text border={{ top: true }}>{data.nitImportador}</Text>
          </View>
        </View>

        <Text
          bold
          textAlign="center"
          border={{ top: true, right: true, left: true }}
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          MERCANCÍA
        </Text>
        <View>
          <View width="50%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              DESCRIPCIÓN
            </Text>
            <Text border={{ top: true }}></Text>
          </View>
          <View
            width="50%"
            flexDirection="column"
            border={{ top: true, right: true, bottom: true }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              ARANCEL
            </Text>
            <Text border={{ top: true }}></Text>
          </View>
        </View>

        <View style={{ marginTop: "2px" }}>
          <View width="25%" borderContent>
            <Text bold>FACTURA COMERCIAL Nº:</Text>
          </View>
          <View width="25%" border={{ top: true, right: true, bottom: true }}>
            <Text></Text>
          </View>
          <View width="25%" border={{ top: true, right: true, bottom: true }}>
            <Text bold>PESO NETO (KG):</Text>
          </View>
          <View width="25%" border={{ top: true, right: true, bottom: true }}>
            <Text></Text>
          </View>
        </View>
        <View>
          <View width="25%" border={{ left: true, right: true, bottom: true }}>
            <Text bold>ADUANA:</Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text bold>PESO BRUTO (KG):</Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
        </View>
        <View>
          <View width="25%" border={{ left: true, right: true, bottom: true }}>
            <Text bold>ORIGEN:</Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text bold>DESTINO:</Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
        </View>
        <View>
          <View width="25%" border={{ left: true, right: true, bottom: true }}>
            <Text bold>TIPO DE DESPACHO:</Text>
          </View>
          <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
          <View
            style={{
              backgroundColor: "#92d050",
            }}
            width="25%"
            border={{ right: true, bottom: true }}
          >
            <Text fontSize="10px" bold>
              DIM:
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#92d050",
            }}
            width="25%"
            border={{ right: true, bottom: true }}
          >
            <Text fontSize="10px" bold></Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          textAlign="center"
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          COSTOS IMPOSITIVOS EN ADUANA
        </Text>
        <Text
          bold
          fontSize="10px"
          textAlign="center"
          border={{ right: true, left: true, bottom: true }}
        ></Text>
        <Text
          bold
          borderContent
          textAlign="center"
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          TOTAL DESEMBOLSOS (Bs)
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "Nº",
              name: "index",
              type: "index",
              width: "5",
            },
            {
              label: "DETALLE",
              name: "name",
              width: "22",
            },
            {
              label: "EMITIDO POR",
              name: "provider",
              width: "22",
            },
            {
              label: "Nº DOCUMENTO",
              name: "number",
              width: "21",
            },
            {
              label: "CLIENTE",
              name: "client",
              width: "15",
            },
            {
              label: "AGENCIA",
              name: "client",
              width: "15",
            },
          ]}
          border={{ top: true, right: true, bottom: true, left: true }}
          data={data.historialReceptionVerificationDocuments}
        />
        <View>
          <View width="70%" border={{ left: true, right: true, bottom: true }}>
            <Text color="red" fontSize="12px" bold>
              TOTALES
            </Text>
          </View>
          {/* <View width="25%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View> */}
          <View width="15%" border={{ right: true, bottom: true }}>
            <Text bold></Text>
          </View>
          <View width="15%" border={{ right: true, bottom: true }}>
            <Text></Text>
          </View>
        </View>
        <View>
          <View
            style={{ backgroundColor: "#ffc000" }}
            width="27%"
            border={{ left: true, right: true, bottom: true }}
          >
            <Text color="red" fontSize="12px" bold>
              TOTAL LIQUIDACIÓN
            </Text>
          </View>
          <View
            width="43%"
            fontSize="12px"
            style={{ backgroundColor: "#ffc000" }}
            border={{ right: true, bottom: true }}
          >
            <Text></Text>
          </View>
          <View
            width="30%"
            style={{ backgroundColor: "#ffc000" }}
            border={{ right: true, bottom: true }}
          >
            <Text bold></Text>
          </View>
        </View>
        <View
          justifyContent="space-around"
          style={{ marginTop: "100px" }}
          alignItems="center"
        >
          <View width="25%" border={{ top: true }} justifyContent="center">
            <Text fontSize="10px">Agencia Aduanera</Text>
          </View>
          <View width="25%">
            <Text fontSize="10px">
              {dayjs().format("DD")} de {dayjs().format("MMMM")} de{" "}
              {dayjs().format("YYYY")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateRoadmap;
