import FormDefault from "../../components/Forms/FormDefault";
const FormReadPdf = () => {
  return (
    <div>
      <FormDefault />
    </div>
  );
};

export default FormReadPdf;
