import * as Yup from "yup";
import { toast } from "react-toastify";
import { H2, H3 } from "../../components/Text";
import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import { requestAuth } from "../../components/services/RequestService";
import { validateArray, validateStatus } from "../../utils/validate";
import { validateType } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import FormDefault from "../../components/Forms/FormDefault";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import TableComplete from "../../components/Tables/TableComplete";
import {
  adjuntarDex,
  readDam,
  readDim,
  readParte,
} from "../../components/services/ReadFile";
import { useEffect } from "react";

const FillForm = (props) => {
  const navigate = useNavigate();
  const {
    procedure,
    reload,
    onClose,
    rolId,
    step,
    typeId,
    openModal,
    urlApi = null,
    handleSendProcedure,
  } = props;

  const { data, loading, call, setData } = Request({
    urlApi: urlApi ? urlApi : `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=2`,
  });

  function handleExcelModal(field) {
    navigate(
      `/rol/${rolId}/procedure-type/${typeId}/step/${step}/procedure/${procedure.id
      }/generate/${field.url}${field.type === "reviewExcel"
        ? "/review"
        : field.type === "correctExcel"
          ? "/answer"
          : ""
      }`
    );
    onClose();
  }

  useEffect(() => {
    if (urlApi != null)
      setData(x => ({ ...x, fields: data }))
  }, [loading])


  function dataIntialValues(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]:
          (element.initial === "false" ? false : element.initial) || "",
      };
    });
    return aux;
  }

  function dataFields(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]: element.validate
          ? validateType[element.type] || null
          : null,
      };
    });
    return aux;
  }
  async function onSubmit(values, resetForm, fields) {
    const result = await Promise.all(
      fields.map(async (element) => {
        if (element.type === "file" || element.type === "fileRead") {
          if (
            (values[element.name] === "" || values[element.name] === null) &&
            (element.initial === "" || element.initial === null)
          ) {
            return null;
          } else if (
            (values[element.name] !== "" && !element.initial) ||
            (values[element.name] !== "" &&
              values[element.name] !== element.initial)
          ) {
            let formData = new FormData();
            formData.append("Name", element.name);
            formData.append("File", values[element.name]);
            formData.append("originalName", values[element.name].name);
            formData.append("Status", "verified");
            formData.append("ProcedureId", procedure.id);
            const fileContents = await requestAuth(
              "post",
              "/files",
              formData
            ).then((res) => {
              return {
                Value: res.data.url,
                ProcedureId: procedure.id,
                FieldId: element.id,
                FileId: res.data.id,
                name: element.name,
                url: element.url,
                type: element.type,
              };
            });
            return fileContents;
          } else {
            return {
              Value: element.initial,
              ProcedureId: procedure.id,
              FieldId: element.id,
              name: element.name,
              url: element.url,
              type: element.type,
            };
          }
        } else if (element.type === "selectNewOption") {
          if (values[element.name + "_isNew"]) {
            await handleCreateOption(
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
              element.url
            );
          }
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        } else {
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        }
      })
    );
    if (procedure.category === "multiple") {
      await submitMultiDataset(
        result.filter((n) => n),
        resetForm,
        values.btnSecondary
      );
    } else {
      await submitDataset(
        result.filter((n) => n),
        resetForm,
        values.btnSecondary
      );
    }
  }

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }
  async function submitDataset(values, resetForm, btnSecondary) {
    await requestAuth("post", "/dataset", values)
      .then(async () => {
        const listRead = values.filter((f) => f.type === "fileRead");
        if (listRead && listRead.length > 0) {
          await readDataProcedure(0, listRead, [], resetForm, btnSecondary);
        } else {
          reload();
          onClose();
          resetForm();
          if (btnSecondary) {
            await handleSendProcedure(procedure, reload);
          }
          toast.success("Envio exitoso");
        }
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }

  async function readDataProcedure(
    index,
    fields,
    result,
    resetForm,
    btnSecondary
  ) {
    if (index < fields.length) {
      switch (fields[index].url) {
        case "adjuntarDim":
          await readDim(
            fields,
            index,
            procedure,
            result,
            async (indexRecibe, fieldsRecibe, resultRecibe) =>
              await readDataProcedure(
                indexRecibe,
                fieldsRecibe,
                resultRecibe,
                resetForm,
                btnSecondary
              )
          );
          break;
        case "dimRegularizada":
          await readDim(
            fields,
            index,
            procedure,
            result,
            async (indexRecibe, fieldsRecibe, resultRecibe) =>
              await readDataProcedure(
                indexRecibe,
                fieldsRecibe,
                resultRecibe,
                resetForm,
                btnSecondary
              )
          );
          break;
        case "adjuntarDex":
          await adjuntarDex(
            fields,
            index,
            procedure,
            result,
            async (indexRecibe, fieldsRecibe, resultRecibe) =>
              await readDataProcedure(
                indexRecibe,
                fieldsRecibe,
                resultRecibe,
                resetForm,
                btnSecondary
              )
          );
          break;
        case "documentoDam":
          await readDam(
            fields,
            index,
            procedure,
            result,
            async (indexRecibe, fieldsRecibe, resultRecibe) =>
              await readDataProcedure(
                indexRecibe,
                fieldsRecibe,
                resultRecibe,
                resetForm,
                btnSecondary
              )
          );
          break;
        case "parteDeRecepcion":
          await readParte(
            fields,
            index,
            procedure,
            result,
            async (indexRecibe, fieldsRecibe, resultRecibe) =>
              await readDataProcedure(
                indexRecibe,
                fieldsRecibe,
                resultRecibe,
                resetForm,
                btnSecondary
              )
          );
          break;
        default:
          break;
      }
    } else {
      const badResult = result.filter(
        (read) => !validateStatus(read.readStatus) && read.validate
      );
      if (validateArray(badResult)) {
        reload();
        onClose();
        resetForm();
        toast.error(badResult[0].readData);
      } else {
        reload();
        onClose();
        resetForm();
        if (btnSecondary) {
          handleSendProcedure(procedure, reload);
        }
        toast.success("Envio exitoso");
      }
    }
  }

  async function submitMultiDataset(values, resetForm, btnSecondary) {
    await requestAuth("get", `/procedure/${procedure.id}/sub-procedures`)
      .then((resp) => {
        submitDataset(
          fillSubProcedures(values, resp.data),
          resetForm,
          btnSecondary
        );
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }

  function fillSubProcedures(values, list) {
    let fillProcedure = values;
    list.forEach((element) => {
      const subProcedure = values.map((item) => ({
        ...item,
        ProcedureId: element.id,
      }));
      fillProcedure = [...fillProcedure, ...subProcedure];
    });
    return fillProcedure;
  }

  function handleReaload() {
    call();
    reload();
  }

  function listSubProcedure() {
    openModal(<ListSubProcedure {...props} />);
  }
  if (!loading) {
    return <Circle />;
  }
  return (
    <>
      {validateArray(data.fields) ? (
        <>
          <div className="flex justify-between mb-2">
            <H2>
              Llenar formulario de trámite{" "}
              <span className="font-bold text-red-600">
                {data.procedureNumber}
              </span>
            </H2>
            {procedure.category === "multiple" && (
              <Button onClick={listSubProcedure} className="py-1 px-3">
                <i className="fas fa-list-ol"></i>
              </Button>
            )}
          </div>
          <FormDefault
            fields={data.fields
              .sort((a, b) => a.order - b.order)
              .map((value, index) => ({
                ...value,
                id: procedure.id,
                fieldId: value.id,
                label: value.label,
                labelCustom: (
                  <>
                    <span className="text-green-600">{index + 1}.</span>{" "}
                    {value.label}
                  </>
                ),
                name: value.name,
                validate: value.validate,
                lockEdition: value.lockEdition,
                placeholder: "Complete el campo",
                type: value.type === "fileRead" ? "file" : value.type,
                typeInput: value.type === "fileRead" ? "file" : value.type,
                initial: value.initial ? value.initial : null,
                urlApi: value.url
                  ? value.url.includes("/")
                    ? value.url
                    : `/Dropdown/${value.url}/options`
                  : "",
                urlInitial: value.url,
                value: "name",
                labelOption: "name",
                action: () => handleExcelModal(value),
                reload: handleReaload,
              }))}
            initialValues={dataIntialValues(data.fields)}
            validationSchema={Yup.object().shape(dataFields(data.fields))}
            onSubmit={async (values, resetForm) =>
              await onSubmit(values, resetForm, data.fields)
            }
            buttonFixed={true}
            buttonSecondary={true}
            buttonName="Registrar"
          />
        </>
      ) : (
        <H3>NO SE TIENE NINGÚN FORMULARIO</H3>
      )}
    </>
  );
};

const ListSubProcedure = (props) => {
  const { procedure, reload, onClose, rolId, step, typeId, openModal } = props;
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/procedure/${procedure.id}/sub-procedures`,
  });

  function createSubProcedure() {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres adicionar un sub tramite?</H3>}
        onClose={dialogClose}
        method="post"
        url={`/Procedure/${procedure.id}/sub-procedures`}
        handleSuccess={call}
        texBtn="Adicionar"
      />
    );
  }
  function formProcedure() {
    openModal(<FillForm {...props} />);
  }
  function onClick(item) {
    openDialog(
      <FillForm
        {...props}
        reload={call}
        procedure={item}
        onClose={dialogClose}
      />
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-1">
          <Button
            onClick={formProcedure}
            className="py-0.5 px-2 bg-[#FBCE00] text-[#000]"
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
          <H2>Lista de sub procedimientos</H2>
        </div>
        <Button onClick={createSubProcedure} className="py-1 px-3">
          <i className="fas fa-plus"></i>
        </Button>
      </div>
      {!loading ? (
        <Circle />
      ) : data.length > 0 ? (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "id",
                label: "Trámite",
              },
              {
                name: "internCode",
                label: "Nro. interno",
              },
              {
                name: "dimNumber",
                label: "Nro. DIM/DEX",
              },
              // {
              //   name: 'add',
              //   type: 'action',
              //   label: 'Opciones',
              //   sticky: true,
              //   actions: [
              //     {
              //       label: 'Añadir',
              //       icon: 'fas  fa-edit',
              //       action: handleForm,
              //       color: 'text-[#1d4ed8]',
              //     },
              //     {
              //       label: 'Añadir',
              //       icon: 'fas fa-trash',
              //       action: handleDelete,
              //       color: 'text-red-500',
              //     },
              //   ],
              // },
            ]}
            data={data}
            onClick={onClick}
          />
        </div>
      ) : (
        <div className="">No hay tramites</div>
      )}
    </div>
  );
};

export default FillForm;
