import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import logo from "../../assets/logo.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";

const RenderPdf = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/rvd`,
  });

  // async function uploadFile(blob) {
  //   var file = new File([blob], 'filename.pdf', {
  //     type: 'application/pdf',
  //     lastModified: Date.now(),
  //   })
  //   let formData = new FormData()
  //   formData.append('Name', 'prueba231')
  //   formData.append('File', file)
  //   formData.append('Type', 'File')
  //   formData.append('CompanyId', 1)
  //   console.log(formData)
  //   await requestAuth('post', '/files', formData)
  //     .then((res) => {
  //       console.log(res)
  //     })
  //     .catch(() => {})
  // }

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent>
          <View
            width="32%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} style={{ height: "30px" }} />
          </View>
          <View
            width="36%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="0 10px"
          >
            <Text textAlign="center" fontSize="11px" bold>
              ARCHIVO DE DOCUMENTOS
            </Text>
          </View>
          <View width="32%" flexDirection="column">
            <View border={{ bottom: true }}>
              <Text minWidth="40%" bold border={{ right: true }}>
                Fecha de Inicio:
              </Text>
              <Text>{dayjs(data.initialDate).format("DD/MM/YYYY HH:mm")}</Text>
            </View>
            {/* <View border={{ bottom: true }}>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número Sistema:
              </Text>
              <Text>{data.systemNum}</Text>
            </View> */}
            <View>
              <Text minWidth="40%" bold border={{ right: true }}>
                Número Interno:
              </Text>
              <Text>{procedure.numRef}</Text>
            </View>
            {/* <View>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número de C.:
              </Text>
              <Text>{data.numberC}</Text>
            </View> */}
          </View>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#1a1a1a" }}
        >
          <Text color="white" bold>
            I. DATOS DEL TRAMITE
          </Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Cliente:
          </Text>
          <Text>{data.client}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Mercadería:
          </Text>
          <Text>{data.product}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Aduana:
          </Text>
          <Text>{data.custom}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Régimen Aduanero:
          </Text>
          <Text>{data.customsRegime}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              Procedencia:
            </Text>
            <Text>{data.origin}</Text>
          </View>
          <View minWidth="50%">
            <Text bold border={{ right: true, left: true }}>
              Número de Factura:
            </Text>
            <Text>{data.invoiceNumber}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Documento de embarque:
          </Text>
          <Text>{data.shipingDoc}</Text>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#1a1a1a" }}
        >
          <Text color="white" bold>
            II. RECEPCIÓN Y VERIFICACIÓN DE DOCUMENTOS
          </Text>
        </View>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "dateInitialHour",
              type: "date",
              width: "13",
            },
            {
              label: "Documento",
              name: "name",
              width: "30",
            },
            {
              label: "Enlace",
              name: "url",
              type: "link",
              width: "9",
            },
            {
              label: "Número",
              name: "number",
              width: "15",
            },
            {
              label: "Presentación",
              name: "presentation",
              width: "11",
            },
            {
              label: "Estado",
              name: "status",
              width: "13",
            },
            {
              label: "Recepción",
              name: "provider",
              width: "9",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={data.historialReceptionVerificationDocuments}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderPdf;
