import CallServiceTable from "../../components/Tables/CallServiceTable";
import { H3, Anchor } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import TableComplete from "../../components/Tables/TableComplete";
import { Circle } from "../../components/Animation";
import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import ListDocument from "../../components/common/ListDocument";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";

const ClientList = () => {
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function viewProcedure(procedure) {
    openModal(
      <ProcedureData procedure={procedure} onClose={onClose} user={user} />
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} deleteDocument={false} />);
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  return (
    <div>
      <CallServiceTable
        onClick={viewProcedure}
        urlApi={`procedure/byuserid/${user?.user?.id}/companyId/${user?.company[0]?.id}/pag`}
        // addFilters={[
        //   {
        //     name: "number",
        //     label: "Número de Proceso",
        //     filter: true,
        //   },
        //   {
        //     name: "dimNumber",
        //     label: "Número de DIM",
        //     filter: true,
        //   },
        //   // {
        //   //   name: 'clientCode',
        //   //   label: 'Código de Cliente',
        //   //   filter: true,
        //   // },
        //   // {
        //   //   name: 'billNumber',
        //   //   label: 'Número de factura',
        //   //   filter: true,
        //   // },
        //   // {
        //   //   name: 'docEmbarque',
        //   //   label: 'Nro de embarque',
        //   //   filter: true,
        //   // },
        // ]}
        // filters={[
        //   {
        //     name: "ForQueryDate",
        //     label: "Validar fecha por ...",
        //     options: [
        //       {
        //         label: "Fecha de validación",
        //         name: "validationDate",
        //       },
        //       {
        //         label: "Fecha de inicio",
        //         name: "procedureStartDate",
        //       },
        //     ],
        //   },
        //   {
        //     // url: '/company/getallcompanies',
        //     // name: 'companyId',
        //     // valueOption: 'id',
        //     // labelOption: 'razonSocial',
        //     type: "rangeDate",
        //   },
        // ]}
        header={[
          {
            name: "numRef",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM",
            filter: true,
          },
          {
            name: "nroDeChasis",
            label: "Nro. Chasis",
            filter: true,
          },
          {
            name: "billNumber",
            label: "Nro. factura",
            filter: true,
          },
          // { name: "companyName", label: "Cliente" },
          // {
          //   name: "prioridad",
          //   label: "Prioridad",
          //   type: "custom",
          //   builder: (value) => {
          //     return (
          //       <div
          //         className={`text-white ${
          //           value === "ALTA"
          //             ? "bg-[#fd3838A1]"
          //             : value === "MEDIA"
          //             ? "bg-[#f3e40c51] text-[#747474]"
          //             : value === "BAJA"
          //             ? "bg-[#4fce4431] text-gray-500"
          //             : "text-gray-500"
          //         } text-center rounded-full py-1 px-2`}
          //       >
          //         {value}
          //       </div>
          //     );
          //   },
          // },
          // { name: "procedureTypeName", label: "Tipo de tramite" },
          { name: "stepDetail", label: "Paso actual" },
          { name: "nextStep", label: "Progreso" },
          { name: "aduana", label: "Aduana" },
          // { name: "initialDate", label: "Fecha de inicio" },
          // { name: "liquidadorAsignado", label: "Liquidador" },
          {
            name: "canal",
            label: "Canal",
            type: "custom",
            builder: (value) => {
              return (
                <div
                  className={`text-white ${
                    value === "Canal Rojo"
                      ? "bg-[#fd3838A1]"
                      : value === "Canal Amarillo"
                      ? "bg-[#f3e40c51] text-[#747474]"
                      : value === "Canal Verde"
                      ? "bg-[#4fce4431] text-gray-500"
                      : "text-gray-500"
                  } text-center rounded-full py-1 px-2`}
                >
                  {value}
                </div>
              );
            },
          },
          {
            name: "Reports",
            type: "action",
            sticky: true,
            label: "Reportes",
            actions: [
              {
                label: "Generar hoja de ruta",
                icon: "fas fa-route",
                action: generateRoadmap,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Ver documentos",
                icon: "fa-solid fa-folder",
                action: handleDocument,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Generar informe de la recepción de documentos",
                icon: "fas fa-tasks",
                action: listDocumentPdf,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Generar liquidación - proforma",
              //   icon: "fas fa-coins",
              //   action: generateSettlementProforma,
              //   color: "text-[#1d4ed8]",
              // },
            ],
          },
        ]}
        addUrl={`userId=${localStorage.getItem("id")}`}
        statusPaination={false}
        showTable={true}
      />
    </div>
  );
};

const ProcedureData = ({ procedure }) => {
  const { data, loading } = Request({
    urlApi: `/clientStats/procedures/${procedure.id}/data`,
  });
  if (!loading) {
    return <Circle />;
  }
  function hadleDocument(list) {
    let listDocument = [];
    list.forEach((dataSet) => {
      if ((dataSet.type = "fileStatus")) {
        listDocument = [...listDocument, ...dataSet.fileStates];
      } else {
        listDocument = [
          ...listDocument,
          { ...dataSet, url: dataSet.value, name: dataSet.label },
        ];
      }
    });
    return listDocument;
  }
  return (
    <div>
      <div className="bg-white mb-2 rounded-md p-2">
        <h3 className="text-xl font-semibold text-center">Datos</h3>
        {data.map((item) =>
          item.type !== "fileStatus" && item.type !== "file" ? (
            <div key={item.id} className="flex gap-2 mx-1 my-1">
              <H3>{item.label}:</H3>
              <H3 className="font-normal">{item.value}</H3>
            </div>
          ) : null
        )}
      </div>
      <div className="bg-white rounded-md">
        <h3 className="text-xl font-semibold text-center mb-2">Documentos</h3>
        <TableComplete
          header={[
            {
              name: "name",
              label: "Documento",
            },
            {
              name: "url",
              label: "Enlace",
              type: "custom",
              builder: (item) => {
                if (item) {
                  return (
                    <Anchor
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </Anchor>
                  );
                } else {
                  return "-";
                }
              },
            },
            {
              name: "number",
              label: "Nro",
            },

            {
              label: "Fecha",
              name: "creationDate",
              type: "date",
            },
            {
              name: "presentation",
              label: "Presentación",
            },
            {
              name: "status",
              label: "Estado",
            },
            {
              label: "Proveedor",
              name: "provider",
            },
          ]}
          data={hadleDocument(data)}
        />
      </div>
    </div>
  );
};

export default ClientList;
