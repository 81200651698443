import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Request } from "../../hooks/Request";
import Button from "../Buttons/Button";
import { Tooltip } from "react-tippy";
import { H3 } from "../Text";
import SubModal from "../Modal/SubModal";
import FormDefault from "../Forms/FormDefault";
import * as Yup from "yup";
import { validateArray } from "../../utils/validate";
import { toast } from "react-toastify";
import { requestAuth } from "../services/RequestService";

export const GeneralData = ({ urlApi, reload }) => {
  const { procedureId } = useParams();
  const [activeForm, setActiveForm] = useState(false);
  const [dataForm, setDataForm] = useState(null);

  const { data, loading, call } = Request({
    urlApi: urlApi ? urlApi : null,
  });

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [reload]);

  function dataTable(values) {
    setDataForm(values);
    setActiveForm(!activeForm);
  }
  function dataInitialValues(value) {
    if (value !== undefined) {
      let data = {};
      value.forEach((element) => {
        data = {
          ...data,
          [element.name]: element.value || "",
        };
      });
      return data;
    } else {
      return 0;
    }
  }

  function dataValidation(values) {
    if (values !== undefined) {
      let dataValidation = {};
      values.forEach((element) => {
        dataValidation = {
          ...dataValidation,
          [element.name]: null,
          // [element.name]: validateType[element.type],
        };
      });
      return dataValidation;
    } else {
      return 0;
    }
  }

  async function onSubmit(values, resetForm) {
    const result = validateArray(dataForm.items)
      ? dataForm.items.map((item) => {
          if (values[item.name]) {
            return {
              Value: values[item.name],
              ProcedureId: procedureId,
              FieldId: item.fieldId,
            };
          }
          return null;
        })
      : [];
    onSubmitDataSet(
      result.filter((n) => n),
      resetForm
    );
  }

  function onSubmitDataSet(values, resetForm) {
    requestAuth("post", "/dataset", values)
      .then(() => {
        call();
        resetForm();
        setActiveForm(false);
        toast.success("Envio exitoso");
      })
      .catch((response) => {
        toast.error(
          response.response.data
            ? response.response.data
            : "Se ha producido un error al enviar"
        );
        // setActiveForm(!activeForm)
      });
  }

  return (
    <>
      {!loading ? (
        <div className="w-full h-[100px] flex justify-center items-center">
          <svg className=" animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
            <i className="text-white font-bold fa-solid fa-circle-notch "></i>
          </svg>
        </div>
      ) : validateArray(data) ? (
        data.map((value, index) => (
          <div key={index}>
            <div className="flex justify-start gap-6 items-center bg-[#83c1ff] text-3xl text-[#264fbe] py-3 px-5 rounded-lg">
              <H3>{value.head}</H3>
              <Tooltip
                title="Abrir Formulario"
                position="top"
                trigger="mouseenter"
              >
                <Button
                  onClick={() => dataTable(value)}
                  className="w-[40px] h-[40px] flex items-center justify-center"
                >
                  <FontAwesomeIcon
                    className="text-white text-[18px] sm:text-[20px]"
                    icon={value.icon ? value.icon : "fa-brands fa-wpforms"}
                  />
                </Button>
              </Tooltip>
            </div>
            {value.type === "list" ? (
              <div className="my-2">
                {value.items.map((item) => (
                  <div
                    key={item.fieldId}
                    className="grid grid-cols-2 border-2 border-[#1d4fd854] rounded-lg my-2"
                  >
                    <div className="font-bold text-[#1d4ed8] border-r-2 border-[#1d4fd854] m-2">
                      {item.label}
                    </div>
                    <div className="m-2">{item.initial}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2 my-2">
                {value.items.map((item) => (
                  <div
                    key={item.fieldId}
                    className="border-2 border-[#1d4fd854] rounded-lg"
                  >
                    <div className="font-bold text-[#1d4ed8] m-2">
                      {item.label}
                    </div>
                    <div className="w-full bg-[#1d4fd854] h-[2px]"></div>
                    <div className="m-2">{item.value ? item.value : "--"}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))
      ) : null}
      <SubModal setactivateForm={setActiveForm} activateForm={activeForm}>
        <FormDefault
          tittle={`Formulario de ${dataForm?.head}`}
          fields={
            dataForm && validateArray(dataForm.items)
              ? dataForm.items.map((values) => ({
                  label: values.label,
                  name: values.name,
                  placeholder: "Introduzca los datos",
                  type: values.type,
                  lockEdition: values.name === "a1N°DeDeclaración(Dam)",
                }))
              : []
          }
          initialValues={dataInitialValues(dataForm?.items)}
          validationSchema={Yup.object().shape(dataValidation(dataForm?.items))}
          onSubmit={onSubmit}
          buttonName="Registrar"
        />
      </SubModal>
    </>
  );
};
